import React from "react"
import H2 from "../../core/H2"

const data = [
  {
    img:
      "https://webmobtechcdn.nyc3.cdn.digitaloceanspaces.com/wmt_v4/2021/01/devops-1.png",
  },
  {
    img:
      "https://webmobtechcdn.nyc3.cdn.digitaloceanspaces.com/wmt_v4/2021/01/android_new.png",
  },
  {
    img:
      "https://webmobtechcdn.nyc3.cdn.digitaloceanspaces.com/wmt_v4/2021/01/IOS_dev.png",
  },
  {
    img:
      "https://webmobtechcdn.nyc3.cdn.digitaloceanspaces.com/wmt_v4/2021/01/Amezon.png",
  },
  {
    img:
      "https://webmobtechcdn.nyc3.cdn.digitaloceanspaces.com/wmt_v4/2021/01/Azure.png",
  },
  {
    img:
      "https://webmobtechcdn.nyc3.cdn.digitaloceanspaces.com/wmt_v4/2021/01/Google_Cloud_Platform-Logo.wine-1.png",
  },
]
export const Association = () => {
  return (
      <div className="max-w-screen-lg mx-auto py-12 my-12">
        <div className="mx-auto text-center" data-aos="fade-up">
          <H2 title={`Associations`} />
        </div>
        <div
          className="flex flex-wrap lg:mt-16 md:mt-12 mt-8"
          data-aos="fade-up"
        >
          {data.map(e => (
            <div
              className="lg:w-1/3 md:w-1/3 w lg:px-12 md:px-10 px-20"
              key={Math.random()}
            >
              <img
                src={e?.img}
                alt="Association"
                width="543px"
                height="377px"
                title="Association"
                loading="lazy"
              />
            </div>
          ))}
        </div>
      </div>
  )
}

export default Association
